import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { combineLatest, filter, map, Observable } from 'rxjs';
import { RoleType } from '../enums/user/role-types.enum';
import { NavigationService } from '../services/navigation.service';
import { SelectedOrgService } from '../services/selected-org.service';
import { UserStoreService } from '../services/store/user-store.service';

@Injectable({
  providedIn: 'root'
})
export class RoleGuard  {
  constructor(
    private userStore: UserStoreService,
    private navService: NavigationService,
    private selectedOrgService: SelectedOrgService,
  ) {  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      return this.fnc(route, state);
  }
  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return this.fnc(childRoute, state);
  }

  private fnc(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const forRoles: RoleType[] = route.data['forRoles'] ?? [];
    const forAllAuthed: boolean = route.data['forAllAuthed'] ?? false;
    return combineLatest(this.userStore.state.user$, this.userStore.state.initialized$).pipe(
      filter(vals => vals[1]),
      map(vals => {
        const user = vals[0];
        const initialized = vals[1];
        // console.log('User initialized', initialized, user)

        const selectedOrgId = this.selectedOrgService.getCurrentValue() ?? undefined;
        const roleTypeFound = user?.roles.filter(x => forRoles.includes(x.type) && x.organizationId === selectedOrgId) ?? [];
        const isSysAdmin = !!user?.roles.find(x => x.type === RoleType.SYSTEM_ADMIN);
        const isCustomerForCustomer = !!user?.roles.find(x => x.type === RoleType.CUSTOMER) && forRoles.includes(RoleType.CUSTOMER);



        if (roleTypeFound.length || isSysAdmin || isCustomerForCustomer) {
          return true;
        }
        if (user) {
          if (forAllAuthed) return true;
          this.navService.goToUsersDashboard(user, selectedOrgId);
        } else {
          this.navService.goToSignIn();
        }
        console.error('Auth guard', false, user, state.url, roleTypeFound, selectedOrgId)
        this.navService.wantedUrl = state.url;
        return false;
      })
    );
  }

}
